import { format } from "date-fns"

export const infoFormatter = (fields,info) => {
    return fields.map((key)=>({
                                'key':key,
                                'value':info?.[key]||null
                            }))
}
export const formatDate = (date,type) => {
    // const theDate = new Date(new Date(date).toLocaleDateString('en-US',{timeZone:'Asia/Taipei'}))
    const dateString = new Date(date)
                        .toLocaleDateString(
                            'zh-TW',
                            {
                                year:'numeric',
                                month:'2-digit',
                                day:'2-digit',
                                timeZone:'Asia/Taipei'
                            }
                        )
                        .replace(/\//g,'-')
    // const dateString = format(theDate,'yyyy-MM-dd')
    if(type==='string'){
        return dateString
    }else{
        // return theDate
        return new Date(date)
    }
}
export const formatCurrencyText = (number) => {
    
    return typeof number === 'number'
            ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/,',')
            : number
}