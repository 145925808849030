import "./user.scss"
import Footer from "../../components/footer/Footer.jsx"
import Navbar from "../../components/navbar/Navbar.jsx"
import OrderView from "../../components/orderView/OrderView.jsx"
// import { useState } from "react"
// import { UserContext } from "../../context/UserContext.js"
import useField from "../../hook/useField.js"
import { getOrderStatus } from "../../function/getOrderStatus.js"
import { format, isBefore } from "date-fns"
import ItemList from "../../components/itemList/ItemList.jsx"
import Form from "../../components/form/Form"
import axios from "axios"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Body from "../../components/body/Body"
import React from "react"
import { AuthContext } from "../../context/AuthContext.js"
import { UserContext } from "../../context/UserContext.js"
import { formatDate } from "../../function/formTools.js"
import { formatInTimeZone } from 'date-fns-tz'

const DoubleCheckBtn = ({className,onClick,children}) => {
    const [clicked,setClicked] = React.useState(false)
    return(
        <button
        className={className}
        onClick={()=>{
            if(!clicked){
                setClicked(true)
            }else{
                onClick()
            }
        }}
        onBlur={()=>{
            setClicked(false)
        }}
        >
            {
            clicked?
            '確定 '+children+' ?':
            children}
        </button>
    )
}

const User = ({onlyOrder}) => {
    axios.defaults.withCredentials = true
    const location = useLocation()
    const navigate = useNavigate()
    // const {id} = useParams()
    const {user,dispatch:authDispatch} = React.useContext(AuthContext)
    const [openedOrderId,setOpenedOrderId] = React.useState(null)
    // const [userInfo,setUserInfo] = React.useState({})
    // const [order,setOrder] = React.useState([])
    // const [completedOrder,setCompletedOrder] = React.useState([])
    // const [discount,setDiscount] = React.useState([])
    const {
        fetchData,
        dispatch,
        // hasOrder,
        discount,
        userInfo,
        order,
        // completedOrder,
        ...otherFields
    } = React.useContext(UserContext)
    const infoFormatter = (fields,info) => {
        return fields.map((key)=>({
                                    'key':key,
                                    'value':info?.[key]||null
                                }))
    }
    const editableUserInfo = infoFormatter([
        'username',
        'mobile',
        // 'email',
        'address'
    ],userInfo)
    // const changePasswordFields = [
    //     {'key':'curPassword','value':null},
    //     {'key':'newPassword','value':null}
    // ]
    // const fetchData = async () => {
    //     try {
    //         let _userInfo,
    //             _order,
    //             _completedOrder,
    //             _discount
    //         //fetch user
    //         _userInfo = await axios.get(
    //                         process.env.REACT_APP_API_ADDRESS + `/api/user/${id}`,
    //                         ).then((res)=>res.data[0])
    //         _discount = (_userInfo?.discountIds.length>0)
    //                     ? await axios.get(
    //                         process.env.REACT_APP_API_ADDRESS + `/api/info/discount_by_discountids/`,
    //                         {params:{'discountids':_userInfo.discountIds}}
    //                         ).then((res)=>res.data)
    //                     : []    
    //         _order = (_userInfo?.order.length>0)
    //                     ? await axios.get(
    //                         process.env.REACT_APP_API_ADDRESS +'/api/order/?',
    //                         {params:{'orders':_userInfo.order}}
    //                         ).then((res)=>res.data)
    //                     : []   
    //         _completedOrder = (_userInfo?.completedOrder.length>0)
    //                         ? await axios.get(
    //                         process.env.REACT_APP_API_ADDRESS + '/api/order/completed/?',
    //                         {params:{'orders':_userInfo.completedOrder}}
    //                         ).then((res)=>res.data)
    //                         : []      
    //         setUserInfo(_userInfo)
    //         setDiscount(_discount)
    //         setOrder(_order)
    //         setCompletedOrder(_completedOrder)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // const hasOrder = (orderId) => {
    //     for(const type of ['order','waitingOrder','completedOrder']){
    //         if(state[type].find(({_id})=>_id===orderId)){
    //             return true
    //         }
    //     }
    //     return false
    // }
    const {orders,completedOrders} = React.useMemo(()=>{
        const orders = order.filter(({orderType})=>orderType==='availible')
        const completedOrders = order.filter(({orderType})=>orderType==='completed')
        return {orders,completedOrders}
    },[order])
    const {
        getUserField
    } = useField()
    const DiscountList = () => {
        return(
            <>{
            discount.map(({
                            discountId,
                            discountType,
                            discountAmount,
                            expiryDate
                        })=>{
                            const amount = discountType==='byRate'
                                            ?  `打 ${discountAmount*10} 折`
                                            :  `折 ${discountAmount} 元`
                            return<div 
                            className="discount-item"
                            key={discountId}
                            >
                                <div className="d-title">{`[${discountId}]`}</div>
                                <div className="d-amount">{amount}</div>
                                <div className="d-expiry">{`${format(new Date(expiryDate),"yyyy-MM-dd")} 截止`}</div>
                            </div>
                        }
                        )
            }</>
        )
    }
    const OrderList = ({orders}) => {
        return(
        <>
            {orders
            .sort((a,b)=>new Date(a.startDate).getTime()-new Date(a.startDate).getTime())
            .map((order)=>{
                const {
                    _id,
                    startDate,
                    endDate,
                    roomNumber,
                    addPersonRoomNumber,
                    roomPerson,
                    // orderType,
                    // isDepositApproved,
                    // actualDepositAmount,
                    // transferDepositBefore
                } = order
                const bottomText = getOrderStatus(order)?.text
                const start = formatInTimeZone(formatDate(new Date(startDate)),'Asia/Taipei','yyyy/MM/dd')
                const end = formatInTimeZone(formatDate(new Date(endDate)),'Asia/Taipei','yyyy/MM/dd')
                const dateString = function(){
                    // 正規表達式：匹配相同年份的日期範圍
                    const regex = /^(\d{4})\/(\d{2}\/\d{2})-(\d{4})\/(\d{2}\/\d{2})$/;
                    // 使用 replace 合併相同年份的日期
                    return (start+'-'+end).replace(regex, (match, year1, date1, year2, date2) => {
                        return year1 === year2 ? `${year1}/${date1}-${date2}` : match;
                    });
                } ()
                const rooms = roomNumber.length
                const guests = `${roomPerson}`
                                + ((addPersonRoomNumber.length>0)
                                ?`+${addPersonRoomNumber.length}`
                                :'')
                return(
                <div 
                className="order-item"
                key={_id}
                onClick={()=>{
                    fetchData()
                    setOpenedOrderId(order._id)
                }}
                >
                    {/* <div className="o-i-date">{`${year}/${start}-${end}`}</div> */}
                    <div className="o-i-date">{dateString}</div>
                    <div className="o-i-room">{`預定 ${rooms} 間 ${guests} 人`}</div>
                    <div className="o-i-bottom">{bottomText}</div>
                </div>)
            })}
        </>
        )
    }
    async function handleDeleteUser () {
        try {
            await axios.delete(process.env.REACT_APP_API_ADDRESS+'/api/user/'+userInfo.userId)
            authDispatch({
                type:'LOGOUT'
            })
            navigate('/')
        } catch (error) {
            console.log(error)
        }
    }
    React.useEffect(() => {
        if(!user){
            navigate('/auth')
        }
        // fetchData()
        //下訂單後自動打開
        const id = location.state?.openedOrderId
        if(location.state?.openedOrderId){
            setOpenedOrderId(id)
            window.history.replaceState(null, '')
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <div className="user">
            <div className="user-layer-1">
                <Navbar/>
                <Body>
                    <div className="user-container">
                        {!onlyOrder?
                        <>
                        <Form
                        title={'個人資料'}
                        option={1}
                        info={editableUserInfo}
                        getField={getUserField}
                        uniqueCheckFn={async (input)=>{
                            try {
                                const res = await axios.get(
                                process.env.REACT_APP_API_ADDRESS + '/api/info/user_dup/',
                                {
                                    params:{
                                        ...input,
                                        'inclusive':true
                                    }
                                })
                                return res.data===0
                            } catch (error) {
                                throw error
                            }
                        }}
                        updateFn={async(updatedInfo)=>{
                            await axios.put(
                                process.env.REACT_APP_API_ADDRESS + `/api/user/${userInfo.userId}`,
                                updatedInfo
                                )
                            fetchData()
                        }}
                        />
                        {/* <Form
                        title={'更改密碼'}
                        option={4}
                        info={changePasswordFields}
                        getField={getUserField}
                        updateFn={async(updatedInfo,makeError)=>{
                            //check curPassword
                            await axios.post(
                                process.env.REACT_APP_API_ADDRESS + `/api/user/check_password`,
                                {'password':updatedInfo?.curPassword||''}
                                ).then(({data})=>{
                                    if(!data){
                                        makeError([{'key':'curPassword','msg':'密碼錯誤'}])
                                    }
                                })
                            //update password
                            await axios.put(
                                process.env.REACT_APP_API_ADDRESS + `/api/user/password`,
                                {'curPassword':updatedInfo?.curPassword||'',
                                'newPassword':updatedInfo?.newPassword
                                }
                                )
                        }}
                        btnAlias={
                            {
                                'editing':'重設'
                            }
                        }
                        /> */}
                        <DoubleCheckBtn
                        className="btn-delete-user"
                        onClick={()=>handleDeleteUser()}
                        >
                            刪除帳號
                        </DoubleCheckBtn>
                        {/* <button
                        className="btn-delete-user"
                        onClick={()=>handleDeleteUser()}
                        >deleteUser刪除帳號</button> */}
                        {discount.length>0&&
                        <>
                        <hr/> 
                        <ItemList title={getUserField('discountIds').alias}>
                            <DiscountList/>
                        </ItemList>
                        </>
                        }
                        </>:
                        <>
                        <ItemList title={getUserField('order').alias}>
                            <OrderList
                                orders={
                                    orders?.filter((order)=>getOrderStatus(order)?.status!=='outdated')
                                }
                            />
                        </ItemList>
                        <hr/> 
                        <ItemList title={'歷史訂單（前3筆）'}>
                            <OrderList
                                orders={
                                    new Array(3).fill().map((i,index)=>completedOrders?.[index]).filter(i=>i).sort((a,b)=>isBefore(new Date(a.startDate),new Date(b.startDate))?1:-1)
                                }
                            />
                        </ItemList>
                        </>
                        }
                    </div>
                </Body>
                <Footer/>
            </div>    
            {
            // hasOrder(openedOrderId)&&
            order.find(({_id})=>_id===openedOrderId)&&
            <div className="user-layer-2">
                <OrderView
                order={order.find(({_id})=>_id===openedOrderId)}
                refetch={fetchData}
                close={()=>{
                    setOpenedOrderId(null)
                }}
                />
            </div>
            }       
        </div>
    )
}

export default User