import { useState } from "react"
import React from 'react'
import './form.scss'
import { format } from "date-fns"
import zhTW from "date-fns/locale/zh-TW"
import { formatDate } from "../../function/formTools"
import {formatInTimeZone} from 'date-fns-tz'

const Form = ({
    children,
    title,
    option=1
    //1.editingStyle
    //2.sendingStyle
    //3.displayStyle
    //4.pressThenShowStyle
    ,
    info,
    validateInfo,
    getField,
    uniqueCheckFn,
    updateFn,
    btnAlias,
    hideBtn=false,
    textareaFields=[],
    getEditingStatus=()=>{},
    detectNoChanged=true
}) => {
    const sizeOfFieldTitle = info.reduce((acc,cur)=>{
        const count = (getField(cur.key)).alias.length
        return Math.max(acc,count)
    },0)/4*100
    const [isEditing,setIsEditing] = useState()
    const [updatedInfo,setUpdatedInfo] = useState({})
    const [errors,setErrors] = useState({})
    const [testError,setTestError] = useState(null)
    const valueTranslator = (
        value,
        type,
        ) => {
        switch(type){
            case 'date':
                return formatInTimeZone(formatDate(value),'Asia/Taipei','yyyy/MM/dd (iiii)',{'locale':zhTW})
            case 'datetime-local':
                return formatDate(value)
            case 'number':
                value = value||0
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/,',')
            case 'password':
                return value
            default:
                return value||''
        }
    }
    const getMinMax = (field,type) => {
        let result = {
            'min':null,
            'max':null
        }
        for(const prop of ['min','max']){
            const value = field?.[prop]
            if(value!==null){
                const propType = typeof value
                let newValue
                if(propType === 'number'){
                    newValue = value
                }else if(propType === 'string'){
                    newValue = validateInfo?.[value] || null
                }
                if(type==='date'){
                    newValue = new Date(value).getTime()
                }
                result[prop] = newValue
            }
        }
        return result
    }
    const closeEditing = () => {
        //close editing
        setIsEditing(false)
        setUpdatedInfo(({}))
        setErrors(({}))
        getEditingStatus(false)
    }
    const makeError = (errors) => {
        const formatedErrors = errors.reduce((acc,{key,msg})=>({...acc,[key]:`*${msg}`})
        ,{})
        setErrors((prev)=>({...prev,...formatedErrors}))
        throw formatedErrors
    }
    const handleEditing = (input) => {
        setIsEditing(input)
        getEditingStatus(input)
    }
    const handleUpdate = async () => {
        const validate = async (key,value,info) => {
            const field = getField(key)
            const regex = field?.regex || null
            const unique = field?.unique || false
            const type = field?.type||'string'
            //check regex
            if(regex&&(!value.match(regex))){
                makeError([{'key':key,'msg':'格式錯誤'}])
            }
            //check empty
            if(!value||value?.length===0){
                makeError([{'key':key,'msg':`不得空白`}])
            }
            //check min and max
            const hasMin = Object.keys(field).find(i=>i==='min')?true:false
            const hasMax = Object.keys(field).find(i=>i==='max')?true:false
            // const hasMin = Object.hasOwn(field,'min')
            // const hasMax = Object.hasOwn(field,'max')
            if(hasMin||hasMax){
                const {amount,unit} = function(){
                    switch(type){
                        case 'number':
                            return {
                                    'amount':value,
                                    'unit':''
                                    }
                        case 'date':
                            return {
                                    'amount':new Date(value).getTime(),
                                    'unit':''
                                    }       
                        case 'string': 
                            return {
                                'amount':value.length,
                                'unit':'字'
                                }       
                        default: break    
                    }
                }()
                const {
                    min,
                    max
                } = getMinMax(field,type)
                if(hasMin
                    &&(amount < min)
                    ){
                    makeError([{
                        'key':key,
                        'msg':`不得少於${valueTranslator(min,type)}${unit&&` ${unit}`}`
                    }])
                }
                if(hasMax
                    &&(amount > max)){
                    makeError([{
                        'key':key,
                        'msg':`不得多於${valueTranslator(max,type)}${unit&&` ${unit}`}`
                    }])
                }
            }
            //check unique
            if(unique){
                let res
                try {
                    res = await uniqueCheckFn({[key]:value})
                } catch (error) {
                    makeError([{'key':key,'msg':`無法確認`}])
                }
                if(!res){
                    makeError([{'key':key,'msg':`已被使用`}])
                }
            }
            setErrors((prev)=>({...prev,[key]:null}))
        }
        try {
            const keys = Object.keys(updatedInfo)
            const hasChanged =  !detectNoChanged||
                                keys.map((key)=>{
                                    let same
                                    const value = info.find((i)=>i.key===key).value
                                    if(typeof value ==='object'){
                                        same = JSON.stringify(value) === JSON.stringify(updatedInfo[key])
                                    }else{
                                        same = value === updatedInfo[key]
                                    }
                                    return same
                                }).includes(false)
            if(
                updateFn &&
                hasChanged
                ){
                //validete
                for(const key of keys){
                    await validate(key,updatedInfo[key],info)
                }
                //update
                await updateFn(updatedInfo,makeError)
            }
            //update success then
            closeEditing()
        } catch (error) {
            setTestError(error)
            console.log(error)
            return
        }
    }
    const handleInput = (e,key,value) => {
        e.preventDefault()
        value = value||e.target.value
        const obj = {[key]:value}
        //set input
        setUpdatedInfo((prev)=>({...prev,...obj}))
    }
    const handleSelect = (value,key,isSelected) => {
        const preValue = updatedInfo?.[key]||[]
        const obj = isSelected
                    ? {[key]:preValue.filter((i)=>i!==value)}
                    : {[key]:[...preValue,value].filter((i)=>i).sort((a,b)=>a-b)}
        //set input
        setUpdatedInfo((prev)=>({...prev,...obj}))
    }
    const show = (section) => {
        const getOption = () =>{
            switch(option){
                case 1:
                    return [
                            'fields',
                            'editingBtn',
                            isEditing
                            ?'input'
                            :'value'
                           ]
                case 2:
                    return [
                            'fields',
                            'sendingBtn',
                            'input'
                            ]
                case 3:
                    return [
                            'fields',
                            'value'
                            ]
                case 4:
                    return [
                        'editingBtn',
                        isEditing&&'fields',
                        isEditing&&'input'
                       ]
                default:break
            }
        }
        if(getOption().includes(section)){
            return true
        }else{
            return false
        }
    }
    const EditingBtn = () => {
        return(
            <div className="btn-container">
            {
                show('editingBtn')&&
                (
                    isEditing
                    ?   <>
                            <button 
                            className="btn" 
                            onClick={()=>handleUpdate()}
                            >
                            {
                            btnAlias?.confirm||'確定'
                            }
                            </button>
                            <button 
                            className="btn"
                            onClick={()=>closeEditing()}
                            >
                            {
                            btnAlias?.cancel||'取消'
                            }
                            </button>
                        </>
                    :   <button 
                        className="btn"
                        onClick={()=>handleEditing(true)}
                        >
                        {
                        btnAlias?.editing||'編輯'
                        }
                        </button>
                )
            }   
            {
                show('sendingBtn')&&
                <button 
                className="btn"
                onClick={()=>handleUpdate()}
                >
                {
                btnAlias?.confirm||'送出'
                }
                </button>
            }
            </div>
        )
    }
    return(
        <div className="form">
            {
                testError?
                '錯誤偵測:'+testError:
                ''
            }
            <div className="top">
                <div className="title">{title}</div>
                {
                !hideBtn&&
                <EditingBtn/>
                }
            </div>
            {
            show('fields')
            &&<div className="field-container">
            {
            info.map(({key,value},index)=>{
                const field = {key,...getField(key)}
                const unit = field?.unit
                const valueAlias = field?.option
                                    ? (field.option.find((option)=>option.value===value)).alias
                                    : null
                const type = field?.type||field?.children?.type||null
                const defaultValue = updatedInfo?.[key]||value
                const name = `${title}-${key}`
                return(
                    <div 
                    className="field-item" 
                    style={{'gridTemplateColumns': `${sizeOfFieldTitle}px auto`}}
                    key={name}
                    >
                        <div className="fd-title">{field.alias}</div>
                        {
                        show('value')&&
                        (!field?.children)&&
                        <div className="fd-value">{
                            `${valueTranslator((valueAlias||value),type)} ${unit||''}`
                            }</div>
                        }
                        {
                        show('value')&&
                        (field?.children)&&
                        <div className='fd-items'>{
                            value.map((item)=>{
                                return(
                                <div 
                                className="fd-item"
                                key={`${key}-${item}`}
                                >
                                    {valueTranslator(item,type)}
                                </div>
                                )
                            })
                            }</div>
                        }
                        {
                        show('input')&&
                        (!field?.option)&&
                        (!field?.children)&&
                        (
                        textareaFields.includes('request')
                            ?<textarea
                            id={key}
                            onChange={(e)=>handleInput(e,key)}
                            />
                            :type==='date'
                                ?
                                <label htmlFor={key}>
                                    <input 
                                    className="fd-input"
                                    id={key}
                                    type={type}
                                    autoComplete="off"
                                    min={format(new Date(field?.min),'yyyy-MM-dd')}
                                    max={format(new Date(field?.max),'yyyy-MM-dd')}
                                    defaultValue={format(new Date(value),'yyyy-MM-dd')}
                                    onChange={(e)=>handleInput(
                                        e,
                                        key,
                                        new Date(new Date(e.target.value).toDateString())
                                        )}
                                    />
                                </label>
                                :
                                <label htmlFor={key}>
                                <input 
                                    className="fd-input"
                                    id={key}
                                    type={type}
                                    autoComplete="off"
                                    min={field?.min}
                                    max={field?.max}
                                    defaultValue={defaultValue}
                                    onChange={(e)=>handleInput(e,key,e.target.value)}
                                    />
                                </label>
                            )
                        }
                        {
                        show('input')&&
                        (field?.option)&&
                        (!field?.children)&&
                        <select 
                            name={name} 
                            id={key}
                            className="fd-select"
                            defaultValue={defaultValue}
                            onChange={(e)=>handleInput(e,key)}
                            >
                            {
                            field.option.map((option,index)=>{
                                return(
                                    <option 
                                    value={option.value}
                                    key={`${name}-${option.value}`}
                                    >
                                    {option.alias}
                                    </option>
                                )
                            })}
                        </select>
                        }
                        {
                        show('input')&&
                        (!field?.option)&&
                        (field?.children)&&
                        <div className='fd-items'>{
                            value.map((item)=>{
                                const isSelected = updatedInfo?.[key]
                                                    ? updatedInfo[key].includes(item)
                                                    :false
                                return(
                                <div 
                                className={`fd-item btn ${isSelected&&'selected'}`}
                                key={`${key}-${item}`}
                                onClick={()=>handleSelect(item,key,isSelected)}
                                >
                                    {valueTranslator(item,type)}
                                </div>
                                )
                            })
                            }</div>
                        }
                        {
                        errors?.[key]&&
                        <div className="fd-error">{errors?.[key]}</div>
                        }
                    </div>
                )})
            }
            </div>
            }
            <div className="cld-container">
            {
                React.Children.map(children,(child)=>{
                    if(React.isValidElement(child)){
                        return React.cloneElement(child,{
                            tool:{
                                setIsEditing,
                                handleUpdate
                            }
                        })
                    }
                    return child
                })
            }
            </div>
        </div>
    )
}

export default Form