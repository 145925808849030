import React from 'react'
import './reserveButton.scss'
import { OrderContext } from '../../context/OrderContext'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { formatDate } from '../../function/formTools'

const ReserveButton = ({nextBtn,lastBtn}) => {
    const {order} = React.useContext(OrderContext)
    const {user} = React.useContext(AuthContext)
    const navigate = useNavigate()
  return (
    <div
    className='reserve-btn'
    >
        <div className="next-button-container">
                <div className='next-button'>
                  <button 
                    className={`next-session`}
                    disabled={nextBtn.disabled}
                    id='next'
                    onClick={()=>{
                        if(
                            !user
                            ){
                            // navigate('/auth',{state:{goto:`/reserve/?startDate=${order.startDate.toDateString()}&endDate=${order.endDate.toDateString()}&${order.roomNumber.map(i=>'roomNumber[]='+i).join('&')}`}})
                            navigate(
                              '/auth',
                              {
                                state:{
                                  goto:`/reserve/?`+
                                  `startDate=${order.startDate.toISOString()}&`+
                                  `endDate=${order.endDate.toISOString()}&`+
                                  `${order.roomNumber.map(i=>'roomNumber[]='+i).join('&')}`
                                }
                              }
                            )
                        }else{
                            nextBtn.onClick()
                        }
                    }}
                  >
                    {nextBtn.text}
                  </button>
                  {
                    lastBtn&&
                    <button
                      className={`previous-session`}
                    //   disabled={(curSession===0)}
                      id='previous'
                      onClick={()=>lastBtn.onClick()}
                    >
                      上一步
                    </button>
                  }
                </div>
        </div>
    </div>
  )
}

export default ReserveButton