import { useContext } from 'react'
import { OrderContext } from '../../context/OrderContext'
import './orderForm.scss'
import { zhTW } from 'date-fns/locale'
import { format } from 'date-fns'
import { formatCurrencyText } from '../../function/formTools'
import ReserveButton from '../reserveButton/ReserveButton'
import { formatInTimeZone } from 'date-fns-tz'
const OrderForm = ({setTab}) => {
  const {
    order:order_context,
    deposit,
    discounts,
    dispatch
  } = useContext(OrderContext)
  const order = {...order_context}
  const startDate = formatInTimeZone(new Date(order.startDate),'Asia/Taipei','yyyy/MM/dd (iiii)',{locale:zhTW})
  const endDate = formatInTimeZone(new Date(order.endDate),'Asia/Taipei','yyyy/MM/dd (iiii)',{locale:zhTW})
  const addPerson = order.addPersonRoomNumber.length
  const canAddPerson = order.room.some(({canAddPerson})=>canAddPerson)
  function discountCalc (totalPrice,discount) {
    return {
            'byRate':{
              'amount':Math.ceil(order.totalPrice*(1-discount.discountAmount)),
              'text':`打 ${discount.discountAmount*10} 折`,
            },
            'byPrice':{
              'amount':discount.discountAmount,
              'text':`折扣 ${formatCurrencyText(discount.discountAmount)} 元`,
            }
          }[discount.discountType]
  }
  function handleVegan(e){
    e.preventDefault()
    let _veganNumber = order.veganNumber
    let _veganType = order.veganType
    let noVegan
    function getValidNumber(max,min,input){
      const _input = parseInt(input.replace(/^0+/,''),10)||0
      const _max = Number(max)
      const _min = Number(min)
      if(_input>=_max){
        return _max
      }else if(_input<_min){
        return 0
      }else{
        return _input
      }
    }
    if(e.target.id==='veganType'&&e.target.value==='none'){
      _veganNumber =  0
      _veganType = 'none'
    }else if(e.target.id==='veganType'&&_veganNumber<=0){
      _veganNumber =  1
      _veganType = e.target.value
    }else if(e.target.id==='veganType'){
      _veganType=e.target.value
    }else if(e.target.id==='veganNumber'){
      _veganNumber = getValidNumber(e.target.max,e.target.min,e.target.value)
    }
    noVegan = _veganNumber<=0
    dispatch(
      {
        type:'SET_ORDER',
        payload:{
          'veganNumber':noVegan?0:_veganNumber,
          'veganType':_veganType
        }
      }
      )
  }
  function handleDiscount(e){
    e.preventDefault()
    const value = e.target.value
    const dIndex = discounts.findIndex(({discountId})=>discountId===value)
    const {
      discountId,
      discountPrice
    } = dIndex === -1
        ? {
          'discountId':undefined,
          'discountPrice':0
          }
        : {
          'discountId':value,
          'discountPrice':discountCalc(order.totalPrice,discounts[dIndex]).amount
          }
    const totalPrice = order.roomPrice
                        +order.addPersonPrice
                        -(discountPrice)
    dispatch({
      'type':'SET_ORDER',
      'payload':{
        'discountId':discountId,
        'discountPrice':discountPrice,
        totalPrice,
        'requiredDepositAmount':Math.ceil(totalPrice*deposit.depositRate/100)*100
      }
    })
  }
  function handleAddPerson(e,roomNumber){
    e.preventDefault()
    //已選的房號
    const addPersonRoomNumber = order.addPersonRoomNumber
    //更新房號，如果原本有，就拿掉
    let _addPersonRoomNumber = addPersonRoomNumber.filter((item)=>!(item===roomNumber))
    //如果沒刪除任何房號，代表是要增加房號
    if(addPersonRoomNumber.length===_addPersonRoomNumber.length){
      _addPersonRoomNumber.push(roomNumber)
      _addPersonRoomNumber.sort((a,b)=>a-b)
    }
    //計算價格
    const addPersonPrice = order.room
                              .filter((item)=>_addPersonRoomNumber.includes(item.roomNumber))
                              .reduce((acc,cur)=>acc+cur.totalAddPersonPrice,0)||0
    //計算人數
    const totalPrice = (
      addPersonPrice
      +(order.roomPrice||0)
      +(order.discountPrice||0)
    )
    //更新訂單
    dispatch(
      {
        'type':'SET_ORDER',
        'payload':{
          'addPersonRoomNumber':_addPersonRoomNumber,
          'totalPerson':order.roomPerson+_addPersonRoomNumber.length,
          addPersonPrice,
          totalPrice,
          'requiredDepositAmount':Math.ceil(totalPrice*deposit.depositRate/100)*100
        }
      }
    )
  }
  return (
    <div 
    className='order-form'
    // hidden={enable}
    >
        <div className="o-f-item">
          <div className='o-f-i-top'>
              <div className="o-f-i-left">
                日期
              </div>
              <div className="o-f-i-right column">
                  <span>{`入住: ${startDate}`}</span>
                  <span>{`退房: ${endDate}`}</span>
              </div>
            </div> 
        </div>
        {
          canAddPerson&&
          <div className="o-f-item">
          <div className='o-f-i-top'>
            <div className="o-f-i-left">
              是否加人?
            </div>
            <div className="o-f-i-right">
              <span>{`目前可住宿人數 ${order.roomPerson}+${addPerson} 位。`}</span>
            </div>
          </div>
          <p>{`僅加人，不加床，採併床方式，每間最多一位，含餐食及備品，若有需要，請選擇。`}</p>
          <div className="o-f-i-container wrap">
            {
            order.room.map(({
              roomNumber,
              totalPrice,
              totalAddPersonPrice,
              canAddPerson
            })=>{
              const isClicked = order.addPersonRoomNumber.includes(roomNumber)
              return (
              <div 
                className={`room-item ${canAddPerson && 'clickable'} ${order?.orderType} ${isClicked&&'clicked'}` } 
                key={`o-f-i-addPerson-${roomNumber}`}
                onClick={(e)=>canAddPerson?handleAddPerson(e,roomNumber):null}
              >
                <div className="room-price">
                  <div className='room-number'>{`${roomNumber}`}</div>
                  <div className='right'>{`${formatCurrencyText(totalPrice)} 元`}</div>
                  </div>
                <div className="room-price" >
                  <div className='left'>{canAddPerson?'加人':'不可加人'}</div>
                  <div className='right'>{canAddPerson&&('+'+totalAddPersonPrice+'元')}</div>
                  </div>
              </div>
              )
              })
            } 
          </div>
          </div>
        }
        <div className="o-f-item">
            <div className='o-f-i-top'>
              <div className="o-f-i-left">
                幾位吃素?
              </div>
              <div className="o-f-i-right">
              <select 
                name="veganType" 
                id="veganType"
                value={order.veganType}
                onChange={e=>handleVegan(e)}
                >
                <option value="none">不忌</option>
                <option value="ovo-lacto">蛋奶素</option>
                <option value="vegan">全素</option>
              </select>
              {
              order.veganType!=='none'&&
                <input 
                type='tel'
                id='veganNumber'
                value={
                  (order.veganNumber<=0||!(order.veganNumber))
                  ?0
                  :order.veganNumber
                }
                onChange={e=>handleVegan(e)}
                min={1} 
                max={order.roomPerson} 
                />
              }
              </div>
          </div>
        </div>
        {
          (discounts.length>0)&&
          <div className="o-f-item">
            <div className='o-f-i-top'>
              <div className="o-f-i-left">
              可用折扣
              </div>
              <div className="o-f-i-right">
                <select 
                name="discount" 
                id="discount"
                value={order.discountId}
                onChange={(e)=>handleDiscount(e)}
                >
                  <option
                    value=''
                  >
                    不使用
                  </option>
                  {
                  discounts.map((discount)=>{
                    const {discountId} = discount
                    const {text} = discountCalc(order.totalPrice,discount)
                  // const {amount,text} = {
                  //   'byRate':{
                  //     'amount':Math.ceil(order.totalPrice*(1-discountAmount)),
                  //     'text':`打 ${discountAmount*10} 折`,
                  //   },
                  //   'byPrice':{
                  //     'amount':discountAmount,
                  //     'text':`折扣 ${discountAmount} 元`,
                  //   }
                  // }[discountType]
                  return(
                  <option
                    key={discountId}
                    value={discountId}
                    >
                      {`${discountId}: ${text}`}
                  </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        }
        <div className="o-f-item">
          <div className='o-f-i-top'>
            <div className="o-f-i-left">
              備註
            </div>
            <div className="o-f-i-right">
              <p className="o-f-i-desc">
                （需待民宿方確認。）
              </p>
            </div>
          </div>
          <textarea
          id="textarea-other-request" 
          // onChange={(e)=>handleOtherRequest(e)}
          />
        </div>
        <div className="o-f-result">
            <div className="o-f-r-top">
              <div>
              {
              `原價 ${formatCurrencyText(order.roomPrice)} 元`
              }
              </div>
              {addPerson?
              ` + 加價 ${formatCurrencyText(order.addPersonPrice||0)} 元`:''
              }
              {order?.discountId?
              ` - 折扣 ${formatCurrencyText(order.discountPrice||0)} 元`:''
              }
              </div>
            <div className="o-f-r-bottom">
              <div className="o-f-r-price">
                {
                `總價 ${formatCurrencyText(order.totalPrice)} 元`
                }
              </div>
              <div className="o-f-r-price">
                {
                `訂金 ${formatCurrencyText(order.requiredDepositAmount)} 元`
                }
              </div>
              <div className="o-f-r-price">
                {
                 `請於 ${deposit?.depositBeforeHours} 小時內匯款，逾時訂單取消。`
                }
              </div>
            </div>
        </div>
        <ReserveButton
        nextBtn={{
          text:'完成，下一步',
          onClick:()=>setTab(2)
        }}
        lastBtn={{
          onClick:()=>setTab(0)
        }}
        />
    </div>
  )
}

export default OrderForm